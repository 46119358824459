import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {getImage} from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image";
import Header from "../components/header";
import Footer from "../components/footer";
import {convertToBgImage} from "gbimage-bridge";

export default function LetsStartPage() {
    const {grayBg} = useStaticQuery(
        graphql`
        query {
            grayBg: file(relativePath: { eq: "gray-white.png" }) {
                childImageSharp {
                    gatsbyImageData(
                      breakpoints: [2500]
                      quality: 100
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
    `
    );

    const bgGrayImage = convertToBgImage(getImage(grayBg));

    return (
        <main className="w-full">
            <BackgroundImage Tag="section" className="relative w-full" {...bgGrayImage}>
                <div className="absolute w-full">
                    <Header mode="dark"/>
                </div>
                <div className="font-poppins px-8 pt-40">
                    <div className="text-center text-3xl lg:text-4xl">¿Qué talento(s) estás buscando?</div>
                    <div className="text-center mt-16">
                        Danos un vistazo rápido para poder seleccionar el mejor
                        Headhunter para ti.
                    </div>

                    <form method="post" action="https://getform.io/f/b340ddb9-37b9-47d5-9806-de99071540af" className="max-w-lg mx-auto mt-16">
                        <div><label htmlFor="companyName">Nombre de la empresa*</label></div>
                        <input className="w-full" type="text" name="companyName" required/>
                        
                        <div className="mt-10"><label htmlFor="name">Persona de contacto*</label></div>
                        <div className="grid grid-cols-2 gap-5">
                            <input type="text" name="name" placeholder="Nombre" required/>
                            <input type="text" name="lastname" placeholder="Apellidos" required/>
                        </div>

                        <div className="mt-10"><label htmlFor="email">Correo electrónico*</label></div>
                        <input className="w-full" type="email" name="email" placeholder="usuario@ejemplo.com" required/>

                        <div className="mt-10"><label htmlFor="phone">Teléfono</label></div>
                        <input className="w-full" type="tel" name="phone"/>

                        <div className="mt-10"><label htmlFor="position">Cargo / Posición que buscas</label></div>
                        <input className="w-full" type="text" name="position"/>

                        <div className="mt-10"><label htmlFor="positionType">Tipo de puesto</label></div>
                        <select name="positionType">
                            <option>Remoto</option>
                            <option>Presencial</option>
                            <option>Indiferente</option>
                        </select>

                        <div className="mt-10"><label htmlFor="positionResponsibilities">Principales responsabilidades del cargo</label></div>
                        <textarea name="positionResponsibilities" rows={5} className="w-full" />

                        <div className="mt-10"><label htmlFor="positionRequirements">Habilidades, conocimientos, y experiencia</label></div>
                        <textarea name="positionRequirements" rows={5} className="w-full" />

                        <div className="mt-10">
                            <button type="submit" className="rounded-full border-2 py-3 px-5">Enviar</button>
                        </div>
                    </form>
                </div>
            </BackgroundImage>
            <Footer/>
        </main>
    );
}